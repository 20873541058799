export const ellipseAddress = (address = "", width = 5) => {
  const normalizedWidth = width >= 2 ? width : 2;
  if (!address) {
    return "";
  }
  return `${address.slice(0, normalizedWidth)}...${address.slice(
    -(normalizedWidth - 1)
  )}`;
};

export const objectToBase64 = (object) => {
  try {
    var objectString = JSON.stringify(object);
    return btoa(objectString);
  } catch (error) {
    console.log(error);
    return "";
  }
};
export const base64ToObject = (string) => {
  try {
    var objectString = atob(string);
    return JSON.parse(objectString);
  } catch (error) {
    console.log(error);
    return {};
  }
};
