import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

import useMediaQuery from "hooks/useMediaQuery";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faClose } from "@fortawesome/free-solid-svg-icons";

import { twJoin, twMerge } from "tailwind-merge";
import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "tailwind.config";

// Components
import Button from "components/Button";

// Hooks
import useWallet from "hooks/useWallet";

// Utils
import { ellipseAddress } from "utils";

// Assets
import GrugsLairPNG from "assets/images/grugsLair.png";
import MobileMenuSVG from "assets/icons/mobile-menu.svg";

const tailwind = resolveConfig(tailwindConfig);

const MobileMenu = ({ renderWalletButtons }) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    window.closeMobileMenu = () => setIsOpen(false);
  }, []);

  return (
    <>
      <div
        className="relative z-[1] flex h-10 w-10 items-center justify-center"
        onClick={() => setIsOpen(!isOpen)}
      >
        <img src={MobileMenuSVG} alt="grugs-lair" layout="fill" />
        <FontAwesomeIcon
          icon={faBars}
          className={twJoin(
            "absolute w-5 text-white transition-opacity duration-300",
            isOpen && "opacity-0"
          )}
        />
        <FontAwesomeIcon
          icon={faClose}
          className={twJoin(
            "absolute w-3 text-white transition-opacity duration-300",
            !isOpen && "opacity-0"
          )}
        />
      </div>
      {isOpen && (
        <div
          className="fixed bottom-0 left-0 right-0 top-0"
          onTouchStart={() => setIsOpen(false)}
          onMouseDown={() => setIsOpen(false)}
        />
      )}
      <div
        className={twMerge(
          "fixed left-0 top-0 w-screen touch-none bg-primary600 px-4 pb-8 pt-[88px] transition-all duration-300",
          !isOpen && "-translate-y-full opacity-0"
        )}
      >
        {renderWalletButtons()}
      </div>
    </>
  );
};

const Header = () => {
  const location = useLocation();
  const isMobile = useMediaQuery("(max-width: 955px)");
  const [scrolled, setScrolled] = useState(false);

  const { connect, disconnect, address, domain } = useWallet();

  let lastKnownScrollPosition = useRef(0);
  let ticking = useRef(false);

  useEffect(() => {
    const scrollHandler = (event) => {
      lastKnownScrollPosition.current = window.scrollY;
      if (!ticking.current) {
        window.requestAnimationFrame(() => {
          // simple throttle
          const target = event.target;
          setScrolled((target?.scrollingElement?.scrollTop || 0) > 100);
          ticking.current = false;
        });
        ticking.current = true;
      }
    };
    setScrolled(window.scrollY > 100);
    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, []);

  const renderWalletButtons = () => (
    <div
      className={twMerge(
        "flex items-center justify-end gap-4",
        isMobile && "mt-8 justify-start"
      )}
    >
      <div>
        {
          <Button
            className={twJoin(
              "h-10 text-sm",
              "tablet:h-10 tablet:text-sm",
              isMobile && "shadow-lg"
            )}
            onClick={address ? disconnect : connect}
          >
            {address
              ? domain || ellipseAddress(address)
              : "Connect StarkWallet"}
          </Button>
        }
      </div>
    </div>
  );

  if (location.pathname.includes("api/")) return null;

  return isMobile !== -1 ? (
    <div
      id="NavBar"
      className={twJoin(
        "fixed top-0 z-10 w-full py-4 transition duration-500",
        isMobile ? "py-4 pl-4 pr-5" : "py-[30px]"
      )}
      style={{
        background: scrolled
          ? "rgba(11, 11, 11, 0.9)"
          : "linear-gradient(180deg, rgba(0, 0, 0, 0.59) 0%, rgba(182, 199, 243, 0) 100%)",
        ...(scrolled && { backdropFilter: "blur(40px)" }),
      }}
    >
      <div
        className={twJoin(
          "mx-auto flex max-w-screen-largeDesktop items-center gap-12",
          isMobile ? "justify-between" : "px-8"
        )}
      >
        <div
          className="z-[1]"
          style={{
            filter: `drop-shadow(${tailwind.theme.textShadow.grugSm})`,
          }}
        >
          <img src={GrugsLairPNG} alt="grugs-lair" width={61} height={48} />
        </div>
        {isMobile ? (
          <MobileMenu renderWalletButtons={renderWalletButtons} />
        ) : (
          <div className="flex flex-1 gap-12"></div>
        )}
        {!isMobile && renderWalletButtons()}
      </div>
    </div>
  ) : null;
};

export default Header;
