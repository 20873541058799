import React from "react";
import { twMerge } from "tailwind-merge";

import TaskIcon from "components/TaskIcon";

import useMediaQuery from "hooks/useMediaQuery";

const STATES = {
  SUCCESS: "success",
  FAILED: "failed",
  LOADING: "loading",
  IDLE: "idle",
};

const Task = ({ task }) => {
  const isMobile = useMediaQuery("(max-width: 955px)");

  return (
    <div
      className={twMerge(
        "border border-solid border-grugBorder bg-grugCardBackground20 p-6 transition-colors duration-300",
        isMobile && "p-4"
      )}
    >
      <div
        className={twMerge(
          "flex min-h-[48px] flex-row items-center justify-between gap-4",
          isMobile && "min-h-[32px]"
        )}
      >
        <p
          className={twMerge(
            "mt-2 flex-1 font-avara text-xl font-extrabold text-white",
            isMobile && "mt-1 text-sm"
          )}
        >
          {task.title}
        </p>
        <div
          className={twMerge(
            "flex w-[220px] items-center justify-center",
            isMobile && "w-[176px]"
          )}
        >
          {task.state !== STATES.IDLE ? (
            <TaskIcon type={task.state} />
          ) : (
            task.idleRender
          )}
        </div>
      </div>
      {!!task.extraRender && !task.hideExtraRender && (
        <div
          className={twMerge(
            "mt-6 flex flex-col items-center justify-center font-sora text-sm text-grayCool300",
            isMobile && "mt-4 text-xs"
          )}
        >
          {task.extraRender}
        </div>
      )}
    </div>
  );
};

export default Task;
