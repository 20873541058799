import { base64ToObject } from "utils";

export const getDiscordData = () =>
  new Promise((resolve, reject) => {
    // Open the API route in a new window
    const loginWindow = window.open(
      "/api/oauth/discord",
      "popUpWindow",
      `width=500, height=800, left=${(window.screen.width - 500) / 2}, top=${
        (window.screen.height - 800) / 2
      }`
    );

    // Poll the login window until it's closed or the login is complete
    const interval = setInterval(() => {
      let pathName;
      try {
        // URLs other than our own app is blocked from reading pathname
        pathName = loginWindow?.location?.pathname;
      } catch {}
      const isResult = pathName === "/api/oauth/discord/result";

      if (loginWindow.closed || isResult) {
        clearInterval(interval);
        loginWindow.close();

        if (isResult) {
          const urlParams = new URLSearchParams(loginWindow.location.search);
          const data = base64ToObject(urlParams.get("data"));
          console.log(data);
          const userIsWojak =
            data.isMemberOfGrugsLair &&
            data.userGuild.roles.includes("925168256334069790");
          resolve({
            userIsWojak,
            discordId: data.user.id,
            discordUsername: data.user.username,
          });
        } else {
          resolve(-1);
        }
      }
    }, 1000);
  });
