import { useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import {
  connect as connectWallet,
  disconnect as disconnectWallet,
} from "@argent/get-starknet";

import {
  clearWalletData,
  setWalletAddress,
  setWalletDomain,
  setWalletMobileUnsupportedWarning,
} from "store/actions";

import useMediaQuery from "./useMediaQuery";

const useWallet = () => {
  const isMobile = useMediaQuery("(max-width: 955px)");
  const { address, domain } = useSelector((state) => state.reducerWallet);

  const connect = async () => {
    // if (isMobile) return setWalletMobileUnsupportedWarning(true);

    const connection = await connectWallet({
      modalMode: "alwaysAsk",
      include: ["argentX", "braavos", "argentWebWallet"],
      // exclude: ["argentWebWallet"],
    });
    if (connection && connection.isConnected) {
      setWalletAddress(connection.selectedAddress);
    }
  };

  const disconnect = async () => {
    await disconnectWallet();
    clearWalletData();
  };

  const fetchStarkName = async (address) => {
    try {
      const { data } = await axios.get(
        `https://api.starknet.id/addr_to_domain?addr=${address}`
      );
      setWalletDomain(data.domain);
    } catch {}
  };

  useEffect(() => {
    if (address) {
      fetchStarkName(address);
    }
  }, [address]);

  return { connect, disconnect, address, domain };
};

export default useWallet;
