import axios from "axios"

const API_HOST = process.env.REACT_APP_API_HOST

export const getRaffleData = async (address) => {
  return axios.get(`${API_HOST}/${address}`)
}

export const updateRaffleData = async (payload) => {
  return axios.post(API_HOST, payload)
}