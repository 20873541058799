import axios from "axios";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import CenterLoading from "components/CenterLoading";

import { objectToBase64 } from "utils";

const DiscordOauth = () => {
  const navigate = useNavigate();

  const CLIENT_ID = process.env.REACT_APP_DISCORD_CLIENT_ID;
  const CLIENT_SECRET = process.env.REACT_APP_DISCORD_CLIENT_SECRET;
  const REDIRECT_PATH = "/api/oauth/discord";
  const REDIRECT_URI = window.location.origin + REDIRECT_PATH;

  // const GUILD_ID = "952946952348270622";
  const GUILD_ID = "866462950830506065";
  const SCOPES = ["identify", "email", "guilds.members.read"].join(" ");

  useEffect(() => {
    const handleOAuth = async () => {
      if (window.location.search.includes("code")) {
        try {
          const code = new URLSearchParams(window.location.search).get("code");
          const tokenResponse = await axios.post(
            "https://discord.com/api/oauth2/token",
            new URLSearchParams({
              client_id: CLIENT_ID,
              client_secret: CLIENT_SECRET,
              grant_type: "authorization_code",
              code: code,
              redirect_uri: REDIRECT_URI,
              scope: SCOPES,
            }).toString(),
            {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Accept-Encoding": "application/x-www-form-urlencoded",
              },
            }
          );
          const accessToken = tokenResponse.data.access_token;

          const userResponse = await axios.get(
            "https://discord.com/api/users/@me",
            {
              headers: {
                authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/x-www-form-urlencoded",
                "Accept-Encoding": "application/x-www-form-urlencoded",
              },
            }
          );
          const userGuildResponse = await axios.get(
            `https://discord.com/api/users/@me/guilds/${GUILD_ID}/member`,
            {
              headers: {
                authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/x-www-form-urlencoded",
                "Accept-Encoding": "application/x-www-form-urlencoded",
              },
            }
          );

          const user = userResponse.data;
          const userGuild = userGuildResponse.data;

          const data = objectToBase64({
            isMemberOfGrugsLair: true,
            user,
            userGuild,
          });

          navigate(`${REDIRECT_PATH}/result?data=${data}`);
        } catch (error) {
          if (error?.response?.data?.message === "Unknown Guild") {
            const data = objectToBase64({
              isMemberOfGrugsLair: false,
              error: error.response,
            });
            navigate(`${REDIRECT_PATH}/result?data=${data}`);
          } else {
            console.error("Error, please try again");
          }
        }
      } else {
        window.location.href = `https://discord.com/api/oauth2/authorize?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=code&scope=${SCOPES}`;
      }
    };

    handleOAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <CenterLoading />;
};

export default DiscordOauth;
