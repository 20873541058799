import { RouterProvider, createBrowserRouter } from "react-router-dom";

import CenterLoading from "components/CenterLoading";

import Root from "views/Root";
import Main from "views/Main";
import DiscordOauth from "views/OAuth/Discord";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      { path: "/", element: <Main /> },
      {
        path: "/api/oauth/discord",
        element: <DiscordOauth />,
      },
      {
        path: "/api/oauth/discord/result",
        element: <CenterLoading />,
      },
    ],
  },
]);

function App() {
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
