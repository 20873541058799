import React, { useState } from "react";
import { twJoin, twMerge } from "tailwind-merge";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faLock } from "@fortawesome/free-solid-svg-icons";

import useMediaQuery from "hooks/useMediaQuery";

import TaskButton from "../TaskButton";

const FinalTask = ({ unlocked, finished, onClickTweet, onClickSubmit }) => {
  const isMobile = useMediaQuery("(max-width: 955px)");
  const [username, setUsername] = useState("");
  const [showSubmitButton, setShowSubmitButton] = useState(false);

  return (
    <div
      className={twMerge(
        "mt-16 border border-solid border-grugBorder bg-grugCardBackground20 p-10 transition-colors duration-300",
        isMobile && "m-8 p-5"
      )}
    >
      {!finished ? (
        unlocked ? (
          <div className="flex flex-col items-center justify-center text-center">
            <p
              className={twMerge(
                "text-center font-avara text-xl font-extrabold text-white",
                isMobile && "text-sm"
              )}
            >
              Tweet to Get Your Club
            </p>
            <p
              className={twMerge(
                "mt-1 text-center font-sora text-sm text-grayCool300",
                isMobile && "mt-0.5 text-xs"
              )}
            >
              Make sure to submit your Twitter Username to be eligible for the
              raffle
            </p>
            <TaskButton
              size="small"
              label="Tweet"
              className={twMerge("mt-6 w-fit", isMobile && "mt-3")}
              onClick={() => {
                onClickTweet();
                setShowSubmitButton(true);
              }}
            />

            {showSubmitButton && (
              <>
                <input
                  type="text"
                  value={username}
                  onChange={({ target }) => setUsername(target.value)}
                  className={twJoin(
                    "mt-6 h-9 w-full border border-[#CA5D504D] bg-[#68121E1A] text-center text-xs text-white",
                    "tablet:mt-8 tablet:h-11 tablet:w-[332px] tablet:text-base"
                  )}
                  placeholder="Username (i.e @grugslair)"
                />
                <TaskButton
                  size="large"
                  label="Submit"
                  disabled={!username}
                  className={twMerge("mt-6 w-fit", isMobile && "mt-3")}
                  onClick={() => onClickSubmit(username)}
                />
              </>
            )}
          </div>
        ) : (
          <div
            className={twMerge(
              "flex flex-col items-center justify-center gap-5 text-center font-avara text-xl font-extrabold text-white",
              isMobile && "gap-4 text-sm"
            )}
          >
            <FontAwesomeIcon
              icon={faLock}
              className={twMerge(
                "text-[32px] text-primary400",
                isMobile && "text-2xl"
              )}
            />
            <p>Complete All Tasks to Unlock</p>
          </div>
        )
      ) : (
        <FontAwesomeIcon
          icon={faCircleCheck}
          className="ml-[50%] -translate-x-1/2 self-center text-8xl"
          color="green"
        />
      )}
    </div>
  );
};

export default FinalTask;
