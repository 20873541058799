import React, { useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";

import Button from "components/Button";
import CenterLoading from "components/CenterLoading";

import { getRaffleData, updateRaffleData } from "request";

import useWallet from "hooks/useWallet";
import useScrollLock from "hooks/useScrollLock";
import useMediaQuery from "hooks/useMediaQuery";

import Banner from "./Banner";
import Task from "./Task";
import TaskButton from "./TaskButton";
import FinalTask from "./FinalTask";

import { getDiscordData } from "./utils";

import style from "./style.module.scss";

const STATES = {
  SUCCESS: "success",
  FAILED: "failed",
  LOADING: "loading",
  IDLE: "idle",
};

const INITIAL_PROGRESS_STATE = {
  twitter_followed_grug: STATES.IDLE,
  twitter_followed_briq: STATES.IDLE,
  discord_role_wojak: STATES.IDLE,
  twitter_tweeted: STATES.IDLE,
};

const TWEET_TEXT = `Are you ready to smash CT with this club? 🪨

🚀 Embark on this exciting adventure with us!

#GrugsLairxBriq #StarkNet

`;

const Main = () => {
  const isMobile = useMediaQuery("(max-width: 955px)");
  const { connect, address, domain } = useWallet();

  const [progressStates, setProgressStates] = useState(INITIAL_PROGRESS_STATE);
  const [loading, setLoading] = useState(false);
  useScrollLock(loading);

  // Methods
  const updateData = async (data) => {
    const payload = {
      starknet_wallet_address: address,
      starknet_id: domain,
      ...data,
    };
    await updateRaffleData(payload);
    fetchData(true);
  };

  const fetchData = async (skipLoading = false) => {
    !skipLoading && setLoading(true);
    try {
      const { data } = await getRaffleData(address);
      setProgressStates((prev) => ({
        ...prev,
        discord_role_wojak: data.discord_role_wojak
          ? STATES.SUCCESS
          : STATES.IDLE,
        twitter_followed_grug: data.twitter_followed_grug
          ? STATES.SUCCESS
          : STATES.IDLE,
        twitter_followed_briq: data.twitter_followed_briq
          ? STATES.SUCCESS
          : STATES.IDLE,
        twitter_tweeted: data.twitter_tweeted ? STATES.SUCCESS : STATES.IDLE,
      }));
    } catch {
      // Do nothing on error as it means there are no data associated to the user
    } finally {
      !skipLoading && setLoading(false);
    }
  };

  const discordOauth = async () => {
    if (!address) return connect();
    setProgressStates((prev) => ({
      ...prev,
      discord_role_wojak: STATES.LOADING,
    }));
    const result = await getDiscordData();
    if (result !== -1) {
      setProgressStates((prev) => ({
        ...prev,
        discord_role_wojak: result.userIsWojak ? STATES.SUCCESS : STATES.FAILED,
      }));
      updateData({
        discord_role_wojak: result.userIsWojak,
        discord_id: result.discordId,
        discord_username: result.discordUsername,
      });
      if (!result.userIsWojak) {
        setTimeout(() => {
          setProgressStates((prev) => ({
            ...prev,
            discord_role_wojak: STATES.IDLE,
          }));
        }, 8000);
      }
    } else {
      setProgressStates((prev) => ({
        ...prev,
        discord_role_wojak: STATES.IDLE,
      }));
    }
  };

  const onClickTwitterAction = (link, stateKey) => {
    if (!address) return connect();
    window.open(link);
    if (stateKey) {
      setProgressStates((prev) => ({
        ...prev,
        [stateKey]: STATES.LOADING,
      }));
      setTimeout(() => {
        updateData({
          [stateKey]: true,
        });
        setProgressStates((prev) => ({
          ...prev,
          [stateKey]: STATES.SUCCESS,
        }));
      }, 8000);
    }
  };

  useEffect(() => {
    if (address) {
      fetchData();
    } else {
      setProgressStates(INITIAL_PROGRESS_STATE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  // Render Config
  const tasks = [
    {
      title: "Follow Grug's Lair on Twitter",
      state: progressStates.twitter_followed_grug,
      idleRender: (
        <TaskButton
          label="Follow @grugslair"
          onClick={() =>
            onClickTwitterAction(
              "https://twitter.com/intent/follow?screen_name=grugslair",
              "twitter_followed_grug"
            )
          }
        />
      ),
    },
    {
      title: "Follow Briq on Twitter",
      state: progressStates.twitter_followed_briq,
      idleRender: (
        <TaskButton
          label="Follow @briqNFT"
          onClick={() =>
            onClickTwitterAction(
              "https://twitter.com/intent/follow?screen_name=briqNFT",
              "twitter_followed_briq"
            )
          }
        />
      ),
    },
    {
      title: "Get Wojak role on Grug's Lair Discord",
      state: progressStates.discord_role_wojak,
      idleRender: <TaskButton label="Verify" onClick={discordOauth} />,
      hideExtraRender: progressStates.discord_role_wojak === STATES.SUCCESS,
      extraRender: (
        <>
          <div
            className={twMerge(
              "text-center font-sora text-lg text-grayCool300",
              isMobile && "text-sm"
            )}
          >
            Join the Grug's Lair Discord, secure your 'Wojak' role from the
            #onboarding channel
          </div>
          <Button
            size="small"
            className="mt-3"
            onClick={() => {
              window.open("https://discord.gg/dusBXG3tcy");
              setProgressStates((prev) => ({
                ...prev,
                discord_role_wojak: STATES.IDLE,
              }));
            }}
          >
            Join Now
          </Button>
        </>
      ),
    },
  ];

  return (
    <div className={style.container}>
      <Banner />
      <div className="mx-auto mb-60 box-content flex max-w-screen-maxContent flex-col gap-4 px-6">
        {tasks.map((task, i) => (
          <Task key={`task-${i}`} task={task} />
        ))}

        <FinalTask
          unlocked={
            progressStates.twitter_followed_grug === STATES.SUCCESS &&
            progressStates.twitter_followed_briq === STATES.SUCCESS &&
            progressStates.discord_role_wojak === STATES.SUCCESS
          }
          finished={progressStates.twitter_tweeted === STATES.SUCCESS}
          onClickTweet={() =>
            onClickTwitterAction(
              `https://twitter.com/intent/tweet?text=${encodeURIComponent(
                TWEET_TEXT
              )}&url=https://club.grugslair.xyz/`
            )
          }
          onClickSubmit={(username) => {
            updateData({
              twitter_username: username,
              twitter_tweeted: true,
            });
            setProgressStates((prev) => ({
              ...prev,
              twitter_tweeted: STATES.SUCCESS,
            }));
          }}
        />
      </div>
      {loading && (
        <CenterLoading className="fixed top-0 z-10 bg-grugCardBackground20" />
      )}
      <div
        onClick={() => {
          setProgressStates((prev) => ({
            ...prev,
            discord_role_wojak: STATES.FAILED,
          }));
          setTimeout(() => {
            setProgressStates((prev) => ({
              ...prev,
              discord_role_wojak: STATES.IDLE,
            }));
          }, 8000);
        }}
        style={{ color: "white" }}
      >
        Make discord failed
      </div>
      <div
        onClick={() => {
          updateData({
            discord_role_wojak: false,
            twitter_followed_briq: false,
            twitter_followed_grug: false,
            twitter_tweeted: false,
          });
        }}
        style={{ color: "white" }}
      >
        Reset progress
      </div>
    </div>
  );
};

export default Main;
