import { twJoin, twMerge } from "tailwind-merge";

import useMediaQuery from "hooks/useMediaQuery";

import lairsTaleJPG from "assets/images/lairsTaleBackground.jpg";

import style from "./style.module.scss";

const Banner = () => {
  const isMobile = useMediaQuery("(max-width: 955px)");

  return (
    // padding top relative to header height, banner height is 720 + header height - 1
    <div
      className={twMerge(
        "h-[825px] w-full overflow-hidden bg-black pt-[106px]",
        isMobile && "h-[500px] pt-0",
        style.banner
      )}
    >
      <div className="relative mx-auto h-full w-full max-w-screen-largeDesktop bg-grugBlack">
        <div
          className={twMerge(
            "absolute h-full w-full",
            isMobile && "max-h-[264px]"
          )}
        >
          <img
            src={lairsTaleJPG}
            alt="background"
            className={twJoin(
              "absolute h-full w-full object-cover bg-black",
              isMobile && "object-right-top"
            )}
            layout="fill"
          />
          <div
            className="absolute -left-[50vw] h-full w-[150vw]"
            style={{
              background:
                "linear-gradient(360deg, #0B0B0B 5.92%, rgba(0, 0, 0, 0) 98.26%)",
            }}
          />
        </div>
        <div
          className={twMerge(
            "absolute left-1/2 w-full max-w-[1192px] -translate-x-1/2 px-6",
            isMobile && "px-4"
          )}
        >
          <div
            className={twMerge("mt-10 max-w-[592px]", isMobile && "mt-[204px]")}
          >
            <h1
              className={twMerge(
                "font-avara text-6xl font-extrabold text-white",
                isMobile && "mt-3 text-3xl"
              )}
            >
              Join the Epic Grugs's Lair & Briq Journey
            </h1>
            <p
              className={twMerge(
                "mt-4 font-sora text-2xl font-light text-grayCool300",
                isMobile && "mt-2 text-base"
              )}
            >
              Step into a unique Web3 adventure with Grug's Lair and Briq.
              Follow, join, and participate in our community.
              <br />
              <br />
              Stand a chance to win our exclusive Briq's Club, a gateway to
              assemble the legendary weapon from the past. Let's get started and
              pave the way for an exciting future together!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
