import React from "react";
import { twMerge } from "tailwind-merge";

import Button from "components/Button";
import useMediaQuery from "hooks/useMediaQuery";

const TaskButton = ({ label, size, className, ...rest }) => {
  const isMobile = useMediaQuery("(max-width: 955px)");
  return (
    <Button
      size={size || (isMobile ? "small" : "large")}
      className={twMerge("w-full justify-center", className)}
      {...rest}
    >
      {label}
    </Button>
  );
};

export default TaskButton;
