import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faSpinner,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";

import style from "./style.module.scss";

const TaskIcon = ({ type }) => {
  let icon, color;
  switch (type) {
    case "success":
      icon = faCheckCircle;
      color = "green";
      break;
    case "loading":
      icon = faSpinner;
      color = "white";
      break;
    case "failed":
    default:
      icon = faTimesCircle;
      color = "red";
      break;
  }
  return (
    <FontAwesomeIcon
      icon={icon}
      color={color}
      className={style.task_icon}
      spin={type === "loading"}
    />
  );
};

export default TaskIcon;
